  /* requires:
polyfill.js
*/

const subscribeForm = {
  init: function(formSetting) {
    const { theForm } = formSetting;
    if (!theForm) { return false }
    this.validateOnBlur(theForm)

    // listening for click on the submit button
    const submitBtn = theForm.querySelector('button[type=submit]');
    submitBtn.addEventListener('click', function(e) {
      e.preventDefault();
      const isFormValid = subscribeForm.isFormValid(theForm)
      if (!isFormValid) {
        alert('The highlighted field/s are required.');
        return false;
      } else {
        subscribeForm.submit(formSetting)
      }
    }, true);
  },
  validateOnBlur: function(theForm) {
    if (!theForm) { return false }
    // realtime form fields validation using blur event
    const fields = theForm.querySelectorAll('.required');
    for (let field of fields) {
      const type = field.type;
      if (type === 'email') {
        field.addEventListener('blur', function(e) {
          const x = field.value;
          const re = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!re.test(x)) {
            field.classList.add('is-invalid')
          } else {
            field.classList.remove('is-invalid')
          }
        }, true);
      }
      else if (type === 'tel') {
        field.addEventListener('blur', function(e) {
          const x = field.value;
          const re = /[(]?\d{3}[)]?\s?-?\s?\d{3}\s?-?\s?\d{4}/;
          if (!re.test(x)) {
            field.classList.add('is-invalid')
          } else {
            field.classList.remove('is-invalid')
          }
        }, true);
      }
      else {
        field.addEventListener('blur', function(e) {
          const x = field.value;
          if (!x || $.trim(x) == '') {
            field.classList.add('is-invalid')
          } else {
            field.classList.remove('is-invalid')
          }
        }, true);
      }
    }
  },
  isFormValid: function(theForm) {
    if (!theForm) { return false }
    // form fields validation during submission
    const fields = theForm.querySelectorAll('.required');
    let isFormValid = true;
    for (let field of fields) {
      const type = field.type;
      if (type === 'email') {
        const x = field.value;
        const re = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(x)) {
          field.classList.add('is-invalid')
          isFormValid = false;
        } else {
          field.classList.remove('is-invalid')
        }
      }
      else if (type === 'tel') {
        const x = field.value;
        const re = /[(]?\d{3}[)]?\s?-?\s?\d{3}\s?-?\s?\d{4}/;
        if (!re.test(x)) {
          field.classList.add('is-invalid')
          isFormValid = false;
        } else {
          field.classList.remove('is-invalid')
        }
      }
      else {
        const x = field.value;
        if (!x || $.trim(x) == '') {
          field.classList.add('is-invalid')
          isFormValid = false;
        } else {
          field.classList.remove('is-invalid')
        }
      }
    }
    return isFormValid;
  },
  generateEmarsysUrl: function(formFields, formId) {
    const emarsysFormId = `f=${formId}&`,
          inp_1 = formFields.firstname ? `inp_1=${formFields.firstname}&` : '',
          inp_2 = formFields.lastname ? `inp_2=${formFields.lastname}&` : '',
          inp_3 = formFields.email ? `inp_3=${formFields.email}&` : '',
          inp_9 = formFields.jobtitle ? `inp_9=${formFields.jobtitle}&` : '',
          inp_10 = formFields.address ? `inp_10=${formFields.address}&` : '',
          inp_11 = formFields.city ? `inp_11=${formFields.city}&` : '',
          inp_12 = formFields.state ? `inp_12=${formFields.state}&` : '', // the state
          inp_13 = formFields.zip ? `inp_13=${formFields.zip}&` : '',
          inp_15 = formFields.phone ? `inp_15=${formFields.phone}&` : '',
          inp_18 = formFields.company ? `inp_18=${formFields.company}&` : '',
          inp_2718 = formFields.referring_url ? `inp_2718=${formFields.referring_url}&` : '',
          inp_2721 = formFields.source_url ? `inp_2721=${formFields.source_url}&` : '',
          inp_2726 = formFields.source_domain ? `inp_2726=${formFields.source_domain}&` : '',
          inp_2728 = formFields.adwords_lead ? `inp_2728=${formFields.adwords_lead}&` : '',
          inp_17833 = formFields.facebook_lead ? `inp_17833=${formFields.facebook_lead}&` : '',
          inp_17832 = formFields.bing_lead ? `inp_17832=${formFields.bing_lead}&` : '',
          inp_18500 = formFields.client_id ? `inp_18500=${formFields.client_id}&` : '',
          inp_20 = formFields.industry ? `inp_20=${formFields.industry}&` : '';
    
    const emarsysFormUrl =  'https://link.theuniversityplatform.com/u/register.php?CID=817427910&p=2&a=r&SID=&el=&llid=&counted=&c=&optin=y&' +
          emarsysFormId +
          inp_1 +
          inp_2 +
          inp_3 +
          inp_9 +
          inp_10 +
          inp_11 +
          inp_12 +
          inp_13 +
          inp_15 +
          inp_18 +
          inp_2718 +
          inp_2721 +
          inp_2726 +
          inp_2728 +
          inp_17833 +
          inp_17832 +
          inp_18500 +
          inp_20;
                    
    return emarsysFormUrl;
  },
  submit: function(formSetting) {
    const { theForm, emarsysFormId } = formSetting;
    const readCookie = (name) => {
      let nameEQ = escape(name) + '=';
      let ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return unescape(c.substring(nameEQ.length, c.length));
      }
      return null;
    }

    const formFields = {
      referring_url: document.referrer || '',
      source_url: window.location.href || '',
      source_domain: 'https://' + window.location.host || '',
      adwords_lead: readCookie('adwordsLead') || '',
      facebook_lead: readCookie('facebookLead') || '',
      bing_lead: readCookie('bingLead') || '',
      client_id: readCookie('_ga') ? readCookie('_ga').split('.').slice(2).join('.') : '',
    };

    const formData = new FormData(theForm);

    // add key value pairs to existing formFields
    for (let [name, value] of formData) {
      // console.log(name, value);
      formFields[name] = value;
    }
    
    const emarsysUrl = this.generateEmarsysUrl(formFields, emarsysFormId) || '';
    const emarsysIframe = document.querySelector('#emarsys-iframe');

    // create hidden iFrame to send data to Emarsys
    if (emarsysIframe) { emarsysIframe.remove() }
    const iFrame = document.createElement('iframe');        
    iFrame.setAttribute('id', 'emarsys-iframe');
    iFrame.setAttribute('src', emarsysUrl);
    iFrame.setAttribute('width', '0');                
    iFrame.setAttribute('height', '0');                
    iFrame.setAttribute('style', 'margin: 0; padding: 0; width: 0; height: 0; opacity: 0;');
    document.body.appendChild(iFrame);

    // show form success message
    const formMsg = theForm.querySelector('.form-msg');
    formMsg.classList.remove('alert', 'alert-danger');
    formMsg.classList.add('alert', 'alert-success');
    formMsg.innerHTML = 'Form submission successful.';

    // reset form after succesful submission
    theForm.reset();

    setTimeout(() => {
      formMsg.classList.remove('alert', 'alert-danger');
      formMsg.classList.remove('alert', 'alert-success');
      formMsg.innerHTML = '';
    }, 3000);
  },
}
